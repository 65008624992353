import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import municipiosData from "./locationModel/municipios.json"; // Importamos los datos de municipios
import provinciasData from "./locationModel/provincias.json"; // Importamos los datos de provincias
import {
  FaTrash, // Añadimos el ícono de eliminación
  FaShareAlt, FaMapMarkerAlt, FaBan,
  FaLocationArrow
} from "react-icons/fa";
import "./SiteDetail.css";

const SiteDetail = () => {
  const { id } = useParams();
  //const id = sessionStorage.getItem("locationId");
  const username = sessionStorage.getItem("username");
  const [siteData, setSiteData] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const navigate = useNavigate();

  const isAuthenticated = sessionStorage.getItem("isAuthenticated");

  // Función para obtener los detalles del sitio
  const fetchSiteData = async () => {
    try {
      const response = await fetch(
        `https://rescate-backend.vercel.app/location/id/${id}`
      );
      const data = await response.json();
      setSiteData(data);
    } catch (error) {
      console.error("Error al obtener los datos de la API:", error);
    }
  };

  // Función para obtener los comentarios
  const fetchComments = async () => {
    try {
      const response = await fetch(
        `https://rescate-backend.vercel.app/comment/${id}`
      );
      const data = await response.json();
      setComments(data); // Asegúrate de que 'data' es un array de comentarios
    } catch (error) {
      console.error("Error al obtener los comentarios:", error);
    }
  };

  // Llamar a fetchSiteData y fetchComments cuando el componente se monta
  useEffect(() => {
    fetchSiteData();
    fetchComments();
  }, [id]);

  const handleDeleteComment = async (commentId) => {
    if (
      !window.confirm("¿Estás seguro de que deseas eliminar este comentario?")
    ) {
      return;
    }

    try {
      const response = await fetch(
        `https://rescate-backend.vercel.app/comment/delete/${commentId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        // Recargar los comentarios después de eliminar uno
        fetchComments();
        alert("Comentario eliminado");
      } else {
        alert("Error al eliminar el comentario");
        console.error("Error al eliminar el comentario");
      }
    } catch (error) {
      alert(
        "Error al intentar eliminar el comentario. Intentelo más tarde o contacte con el administrador."
      );
      console.error("Error al intentar eliminar el comentario:", error);
    }
  };

  // Función para obtener el nombre del municipio a partir del ID
  const getMunicipioNombre = (municipioId) => {
    const municipio = municipiosData.find(
      (m) => m.municipio_id === municipioId
    );
    return municipio ? municipio.nombre : "Municipio desconocido";
  };

  // Función para obtener el nombre de la provincia a partir del ID
  const getProvinciaNombre = (provinciaId) => {
    const provincia = provinciasData.find(
      (p) => p.provincia_id === provinciaId
    );
    return provincia ? provincia.nombre : "Provincia desconocida";
  };

  // Maneja el envío de nuevos comentarios
  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!newComment.trim()) {
      alert("Para agregar un comentario es necesario el texto.");
      return;
    }

    try {
      const response = await fetch(
        `https://rescate-backend.vercel.app/comment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: username,
            message: newComment,
            locationid: id,
          }),
        }
      );
      if (response.ok) {
        setNewComment(""); // Limpiar el campo de comentario
        // Recargar los comentarios después de agregar uno nuevo
        fetchComments(); // Vuelve a obtener los comentarios actualizados
        alert("Comentario añadido");
      } else {
        alert("Error al agregar el comentario");
        console.error("Error al agregar el comentario");
      }
    } catch (error) {
      alert(
        "Error al enviar el comentario. Intentelo más tarde o contacte con el administrador"
      );
      console.error("Error al enviar el comentario:", error);
    }
  };

  // Redirigir a la página de cancelación
  const handleRequestCancellation = () => {
    if (isAuthenticated === "true") {
      navigate("/cancelation-request");
    } else {
      alert("Debes estar autenticado para solicitar la cancelación.");
    }
  };

  const handleHow = () => {
    if (siteData?.latitude && siteData?.longitude) {
      const destination = `${siteData.latitude},${siteData.longitude}`;
      const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${destination}`;
      window.open(mapsUrl, "_blank"); // Abre la URL en una nueva pestaña
    } else {
      alert("Las coordenadas de esta ubicación no están disponibles.");
    }
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Detalles del Sitio",
          text: "Mira esta ubicación en Rescate",
          url: window.location.href,
        });
      } catch (error) {
        console.error("Error al compartir:", error);
        alert("Hubo un error al intentar compartir la página.");
      }
    } else {
      alert("La función de compartir no está disponible en este navegador.");
    }
  };

  if (!siteData) return <div className="loading">Cargando...</div>;

  if (siteData.type === "affectedBuild") {
    siteData.ed = "Edificio afectado";
  } else if (siteData.type === "pickUpPoint") {
    siteData.ed = "Punto de recogida de material";
  }

  return (
    <div className="site-details">
      <h2>Detalles del Sitio</h2>
      <u>
        <p>{siteData.ed}</p>
      </u>
      {siteData.type === "affectedBuild" && (
        <div>
          <p>
            Dirección:{" "}
            <label>
              {siteData.street}, {siteData.number}, {siteData.additionalinfo},{" "}
              {getMunicipioNombre(siteData.municipality)},{" "}
              {getProvinciaNombre(siteData.province)}, {siteData.postalcode}
            </label>
          </p>
          <p>
            Persona de contacto: <label>{siteData.contact}</label>
          </p>
          <p>
            Teléfono: <label>{siteData.phone}</label>
          </p>
          <p>
            Descripción: <label>{siteData.description}</label>
          </p>
        </div>
      )}

      {siteData.type === "pickUpPoint" && (
        <div>
          <p>
            Dirección:{" "}
            <label>
              {siteData.street}, {siteData.number}, {siteData.additionalinfo},{" "}
              {getMunicipioNombre(siteData.municipality)},{" "}
              {getProvinciaNombre(siteData.province)}, {siteData.postalCode}
            </label>
          </p>
          <p>
            Persona de contacto: <label>{siteData.contact}</label>
          </p>
          <p>
            Teléfono: <label>{siteData.phone}</label>
          </p>
          <p>
            Descripción: <label>{siteData.descriptionpoint}</label>
          </p>
        </div>
      )}

      {siteData.type === "affectedBuild" && (
        <div className="resources-section">
          <h3>Necesidades de la zona afectada</h3>
          <div className="resources-list">
            {Object.keys(siteData.resources).map((resourceKey) => (
              <div key={resourceKey} className="resource-item">
                <label
                  className={siteData.resources[resourceKey] ? "checked" : ""}
                >
                  <input
                    type="checkbox"
                    checked={siteData.resources[resourceKey]}
                    disabled
                  />
                  {resourceKey.replace(/_/g, " ")}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
<div className="buttonsOptions">
        <button onClick={handleShare} className="share-button">
        <FaShareAlt className="icon" /> <span className="button-text"> Compartir esta página</span>
        </button>
        <button onClick={handleHow} className="how-button">
        <FaLocationArrow className="icon" /> <span className="button-text"> Como llegar</span>
        </button>
        <button
          onClick={handleRequestCancellation}
          className="cancel-request-btn"
        >
          <FaBan className="icon" /> <span className="button-text">Solicitar cancelación de esta ubicación</span>
        </button>
      </div>
      <div className="comments-section">
        <h3>Comentarios sobre el estado actual de esta ubicación</h3>
        <ul className="comments-list">
          {Array.isArray(comments?.comments) && comments.comments.length > 0 ? (
            comments.comments.map((comment, index) => (
              <li key={index} className="comment-item">
                <p className="comment-message">{comment.message}</p>
                <div className="comment-header">
                  <strong>{comment.username}</strong> -{" "}
                  <span className="comment-date">{comment.insertion}</span>
                  {comment.username === username && ( // Mostrar el icono de eliminación solo para los comentarios del usuario
                    <button
                      className="delete-comment-btn"
                      onClick={() => handleDeleteComment(comment.commentid)} // Llamar a la función de eliminación
                    >
                      <FaTrash />
                    </button>
                  )}
                </div>
              </li>
            ))
          ) : (
            <p>No hay comentarios para esta ubicación.</p>
          )}
        </ul>

        {(isAuthenticated === "false" ||
          username === null ||
          username === "") && (
          <h4>
            Para comentar necesita estar autenticado. Si tiene usuario puede{" "}
            <a
              href="/login"
              style={{ color: "#007bff", textDecoration: "underline" }}
            >
              iniciar sesión
            </a>{" "}
            y sino puede{" "}
            <a
              href="/register"
              style={{ color: "#007bff", textDecoration: "underline" }}
            >
              registrarse
            </a>
            .
          </h4>
        )}
        {isAuthenticated === "true" && username && (
          <form onSubmit={handleCommentSubmit} className="comment-form">
            <textarea
              placeholder="Escribe un comentario..."
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              className="comment-input"
              rows="3"
            />
            <button type="submit" className="comment-button">
              Agregar Comentario
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default SiteDetail;
