// AboutUs.js
import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <h1>Sobre Nosotras</h1>
      <p className="about-us-description">
        Después de las catástrofes naturales, hay muchas personas que necesitan ayuda y, a la vez, muchos voluntarios dispuestos a colaborar. Sin embargo, en muchas ocasiones, no se sabe exactamente dónde hace falta esa ayuda o qué es lo que más se necesita. Por eso, creamos esta plataforma: para conectar a quienes necesitan ayuda con quienes están dispuestos a brindarla.
      </p>
      <h2>Nuestro Propósito</h2>
      <p>
        En <strong>Rescate 360</strong>, queremos que la solidaridad fluya de manera más eficiente, permitiendo que las personas que están dispuestas a ayudar encuentren exactamente lo que se necesita, en el momento y lugar adecuado.
      </p>

      <h2>Quiénes Somos</h2>
      <div className="team-members">
        <div className="team-member">
          <img
            src="/images/laura.jpg"  // Ruta de la imagen de Laura en la carpeta public
            alt="Laura López"
            className="team-member-img"
          />
          <h3>Laura López</h3>
          <p>
            Laura, nuestra cordobesa que vive en Cártama (Málaga), es Ingeniera Informática y ex miembro de Protección Civil Málaga. Con su formación técnica como desarrolladora y experiencia en situaciones de emergencia, Laura está comprometida con la creación de soluciones que puedan hacer una diferencia real en momentos de crisis.
          </p>
          <a
            href="https://www.linkedin.com/in/lauralopezburgos/" // Reemplaza con el enlace real de LinkedIn de Laura
            target="_blank"
            rel="noopener noreferrer"
            className="linkedin-link"
          >
            <img
              src="/images/linkedin-logo.png" // Ruta de la imagen del logo de LinkedIn en la carpeta public
              alt="LinkedIn"
              className="linkedin-logo"
            />
          </a>
        </div>

        <div className="team-member">
          <img
            src="/images/marina.jpg"  // Ruta de la imagen de Marina en la carpeta public
            alt="Marina Segura"
            className="team-member-img"
          />
          <h3>Marina Segura</h3>
          <p>
            Marina, nuestra almeriense que vive en Cártama (Málaga). Es titulada en FP Superior de Desarrollo de Aplicaciones Web. Con un enfoque en la tecnología y el desarrollo web, Marina aporta su habilidad técnica para crear una plataforma accesible y funcional que conecta a la comunidad con los recursos necesarios en tiempos de necesidad.
          </p>
          <a
            href="https://www.linkedin.com/in/marinasegurafernandez/" // Reemplaza con el enlace real de LinkedIn de Marina
            target="_blank"
            rel="noopener noreferrer"
            className="linkedin-link"
          >
            <img
              src="/images/linkedin-logo.png" // Ruta de la imagen del logo de LinkedIn en la carpeta public
              alt="LinkedIn"
              className="linkedin-logo"
            />
          </a>
        </div>
      </div>

      <h2>Por Qué Lo Hacemos</h2>
      <p>
        Nos mueve la necesidad de transformar la manera en que la ayuda llega a quienes más la necesitan. Queremos hacer que la solidaridad y la colaboración sean más simples y eficaces. Creemos que la tecnología puede ser una herramienta clave para mejorar la respuesta ante desastres y crisis.
      </p>

      <h2>Únete</h2>
      <p>
        Si compartes nuestra visión y deseas colaborar en nuestro proyecto, no dudes en ponerte en contacto con nosotras. Juntos podemos marcar la diferencia.
      </p>
    </div>
  );
};

export default AboutUs;
