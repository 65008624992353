import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  // Verificamos si el usuario está autenticado
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");

  if (!isAuthenticated) {
    // Redirige a login si no está autenticado
    return <Navigate to="/login" />;
  }

  return children;
};

export default PrivateRoute;