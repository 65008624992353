import React, { Component } from "react";
import "ol/ol.css";
import { Map, View } from "ol";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { OSM } from "ol/source";
import { Vector as VectorSource } from "ol/source";
import { Point } from "ol/geom";
import { Feature } from "ol";
import { fromLonLat, toLonLat } from "ol/proj";
import { Style, Icon } from "ol/style";
import Overlay from "ol/Overlay";
import "./UpdateSite.css";
import provincias from "./locationModel/provincias.json";
import municipios from "./locationModel/municipios.json";
import Select from "react-select";
import recursos from "./locationModel/recursos.json";

class UpdateSite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        type: "",
        resources: {
          agua_potable: true,
          alimentacion: false,
          carpas_cobertores: false,
          herramientas: false,
          higiene_personal: false,
          material_animales: true,
          material_bebes: false,
          material_limpieza: false,
          otros: false,
          primeros_auxilios: false,
          ropa_mantas: false,
          voluntarios_limpieza: false,
        },
        contact: "",
        phone: "",
        description: "",
        descriptionPoint: "",
        latitude: "",
        longitude: "",
        street: "",
        number: "",
        postalCode: "",
        province: "",
        municipality: "",
        additionalInfo: "",
      },
      map: null,
      overlay: null,
      municipiosList: [], // Lista de municipios
      isLoading: false,
    };
    this.mapRef = React.createRef();
    this.overlayRef = React.createRef();
    this.vectorSource = new VectorSource();
  }

  async componentDidMount() {
    const id = sessionStorage.getItem("locationId");
    try {
        const response = await fetch(`https://rescate-backend.vercel.app/location/id/${id}`);
        const data = await response.json();
        this.setState({ formData: data });

        // Filtrar municipios por provincia si ya se tiene uno seleccionado
        const filteredMunicipios = municipios.filter(
          (mun) => mun.provincia_id === data.province
        );
        this.setState({ municipiosList: filteredMunicipios });

        this.updateMapPoint(data.longitude, data.latitude);
    } catch (error) {
        console.error("Error al cargar los datos de la ubicación:", error);
    }

    // Configuración del mapa
    const vectorLayer = new VectorLayer({
      source: this.vectorSource,
    });
    const map = new Map({
      target: this.mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        vectorLayer,
      ],
      view: new View({
        center: fromLonLat([-3.74922, 40.463667]),
        zoom: 6,
      }),
    });
    const overlay = new Overlay({
      element: this.overlayRef.current,
      positioning: "bottom-center",
      stopEvent: false,
      autoPan: false,
    });
    map.addOverlay(overlay);
    this.setState({ map, overlay });

    map.on("singleclick", (event) => {
      const coordinate = toLonLat(event.coordinate);
      this.setState({
        formData: {
          ...this.state.formData,
          latitude: coordinate[1],
          longitude: coordinate[0],
        },
      });
      this.updateMapPoint(coordinate[0], coordinate[1]);
    });
  }

  handleGeocode = async () => {
    let { street, number, municipality, province, postalCode } =
      this.state.formData;

    if (!street || !number || !municipality || !province || !postalCode) {
      alert("Por favor, completa todos los campos de dirección.");
      return;
    }

    // Buscar el nombre del municipio a partir del ID del municipio
    const selectedMunicipality = municipios.find(
      (mun) => mun.municipio_id === municipality
    );

    // Si se encuentra el municipio, actualizar el estado con el nombre
    if (selectedMunicipality) {
      municipality = selectedMunicipality.nombre; // Actualizamos con el nombre del municipio
    }

    // Buscar el nombre de la provincia a partir del ID de la provincia
    const selectedProvince = provincias.find(
      (mun) => mun.provincia_id === province
    );

    // Si se encuentra la provincia, actualizar el estado con el nombre
    if (selectedProvince) {
      province = selectedProvince.nombre; // Actualizamos con el nombre de la provincia
    }

    const address = `${number} ${street}`;
    const city = municipality;
    const state = province;
    const postalCodeValue = postalCode;
    const country = "ES";

    const apiUrl = `https://geocode.maps.co/search?street=${encodeURIComponent(
      address
    )}&city=${encodeURIComponent(city)}&state=${encodeURIComponent(
      state
    )}&postalcode=${encodeURIComponent(
      postalCodeValue
    )}&country=${encodeURIComponent(country)}&api_key=${
      process.env.REACT_APP_GEOCODE_API_KEY
    }`;

    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("No se pudo obtener la ubicación");
      }

      const data = await response.json();

      if (data && data.length > 0) {
        const latitude = parseFloat(data[0].lat);
        const longitude = parseFloat(data[0].lon);

        this.setState({
          formData: {
            ...this.state.formData,
            latitude,
            longitude,
          },
        });

        this.updateMapPoint(longitude, latitude);
      } else {
        alert(
          "No se pudo encontrar la ubicación. Por favor, verifica la dirección."
        );
      }
    } catch (error) {
      console.error("Error al realizar la geocodificación:", error);
      alert("Error al buscar la dirección. Por favor intente de nuevo.");
    }
  };

  updateMapPoint = (longitude, latitude) => {
    const { overlay } = this.state;
    const mapCoordinate = fromLonLat([longitude, latitude]);
    overlay.setPosition(mapCoordinate);
    this.vectorSource.clear();

    const point = new Point(mapCoordinate);
    const iconStyle = new Style({
      image: new Icon({
        src: "/icons/point.png",
        scale: 0.05,
        anchor: [0.5, 0.8],
        anchorXUnits: "fraction",
        anchorYUnits: "fraction",
        imgSize: [16, 16],
        crossOrigin: "anonymous",
      }),
    });

    const feature = new Feature({
      geometry: point,
    });
    feature.setStyle(iconStyle);
    this.vectorSource.addFeature(feature);
  };

  // Manejo de cambio para el select de recursos
  handleChange = (selectedOption, actionMeta) => {
    if (actionMeta) {
      const { name } = actionMeta;
      let value = selectedOption ? selectedOption.map((option) => option.value) : [];

      if (name === "resources") {
        // Crear el objeto actualizado de recursos con 'true' para seleccionados y 'false' para no seleccionados
        const updatedResources = {};
        recursos.forEach((resource) => {
          updatedResources[resource.value] = value.includes(resource.value);
        });

        this.setState({
          formData: {
            ...this.state.formData,
            resources: updatedResources,
          },
        });
      }
    } else {
      const { name, value } = selectedOption.target || {};
      this.setState({
        formData: {
          ...this.state.formData,
          [name]: value,
        },
      });
    }
  };


  handleProvinceChange = (selectedProvince) => {
    const provinceId = selectedProvince.value;
    this.setState(
      {
        formData: {
          ...this.state.formData,
          province: provinceId,
          municipality: "", // Reseteamos el municipio
        },
        municipiosList: municipios.filter((mun) => mun.provincia_id === provinceId), // Filtramos los municipios
      }
    );
  };

  handleMunicipalityChange = (selectedMunicipality) => {
    this.setState({
      formData: {
        ...this.state.formData,
        municipality: selectedMunicipality ? selectedMunicipality.value : "", // Aseguramos que se guarde el ID correcto del municipio
      },
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const id = sessionStorage.getItem("locationId");
    const { formData } = this.state;

    try {
      const response = await fetch(`https://rescate-backend.vercel.app/location/update/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("Ubicación actualizada con éxito");
        window.location.href = "/";
      } else {
        alert("Error al actualizar la ubicación");
      }
    } catch (error) {
      console.error("Error al actualizar la ubicación:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { formData, municipiosList, isLoading } = this.state;
    
    // Aseguramos que formData.resources exista y no sea null ni undefined
    const resourcesOptions = recursos || [];
    const provinceOptions = (provincias || []).map((provincia) => ({
      value: provincia.provincia_id,
      label: provincia.nombre,
    }));
    const municipalityOptions = (municipiosList || []).map((municipio) => ({
      value: municipio.municipio_id,
      label: municipio.nombre,
    }));
  
    // Aseguramos que formData.resources sea un objeto válido
    const selectedResources = Object.keys(formData.resources || {})
      .filter((resource) => formData.resources[resource])
      .map((resource) => {
        return resourcesOptions.find((option) => option.value === resource);
      });
  
    if (!formData) {
      return null;  // No renderizar nada si formData está vacío o no es válido
    }
  
    return (
      <div className="update-site-container">
        {/* Map */}
        <div className="update-site-map" ref={this.mapRef}></div>
  
        {/* Formulario */}
        <form onSubmit={this.handleSubmit} className="update-site-form">
          <h2>Actualizar una ubicación</h2>
  
          <div className="form-group">
            <label htmlFor="type">Tipo de sitio</label>
            <select
              name="type"
              value={formData.type}
              onChange={this.handleChange}
              required
            >
              <option value=""></option>
              <option value="affectedBuild">Edificio Afectado</option>
              <option value="pickUpPoint">Punto de Recogida de Material</option>
            </select>
          </div>
  
          {/* Solo se muestra si es "affectedBuild" */}
          {formData.type === "affectedBuild" && (
            <div className="form-group">
              <label htmlFor="resources">¿Qué necesitas?</label>
              <Select
                name="resources"
                value={selectedResources}
                onChange={this.handleChange}
                options={resourcesOptions}
                isMulti
                isSearchable
                placeholder="Selecciona los recursos necesarios"
              />
            </div>
          )}

          <div className="form-group">
            <label htmlFor="contact">Persona de contacto</label>
            <input
              type="text"
              name="contact"
              placeholder="Persona de contacto"
              value={formData.contact}
              onChange={this.handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Teléfono de contacto</label>
            <input
              type="tel"
              name="phone"
              placeholder="Número de teléfono"
              value={formData.phone}
              onChange={this.handleChange}
              required
            />
          </div>

          {formData.type === "affectedBuild" && (
            <div className="form-group">
              <label htmlFor="description">Descripción del problema</label>
              <textarea
                name="description"
                placeholder="Describe el problema"
                value={formData.description}
                onChange={this.handleChange}
              />
            </div>
          )}

          <h3>Dirección</h3>
          <div className="form-group">
            <label htmlFor="street">Calle</label>
            <input
              type="text"
              name="street"
              placeholder="Calle"
              value={formData.street}
              onChange={this.handleChange}
              required
            />
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="number">Número</label>
              <input
                type="text"
                name="number"
                placeholder="Número"
                value={formData.number}
                onChange={this.handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="postalCode">Código Postal</label>
              <input
                type="text"
                name="postalCode"
                placeholder="Código Postal"
                value={formData.postalcode}
                onChange={this.handleChange}
                required
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="province">Provincia</label>
            <Select
              name="province"
              options={provinceOptions}
              value={provinceOptions.find(
                (option) => option.value === formData.province
              )}
              onChange={this.handleProvinceChange}
              isSearchable
            />
          </div>

          <div className="form-group">
            <label htmlFor="municipality">Municipio</label>
            <Select
              name="municipality"
              options={municipalityOptions}
              value={municipalityOptions.find(
                (option) => option.value === formData.municipality
              )}
              onChange={this.handleMunicipalityChange}
              isSearchable
              isClearable
            />
          </div>

          <div className="form-group">
            <label htmlFor="additionalInfo">Información adicional</label>
            <textarea
              name="additionalInfo"
              value={formData.additionalInfo}
              onChange={this.handleChange}
            />
          </div>

          <div className="form-group">
            <button type="submit" disabled={isLoading}>
              {isLoading ? "Actualizando..." : "Actualizar Ubicación"}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default UpdateSite;
