// src/components/PrivacyPolicy.js
import React from "react";
import "./PrivacyPolicy.css";

function PrivacyPolicy() {
  return (
    <div className="privacy-policy-container">
      <h1>Política de Privacidad</h1>
      <p>
        En Rescate 360, valoramos y respetamos su privacidad. Esta política de privacidad describe cómo recopilamos, usamos y protegemos su información personal cuando utiliza nuestro sitio web.
      </p>

      <h2>1. Información que Recopilamos</h2>
      <p>
        Podemos recopilar la siguiente información cuando usted utiliza nuestro sitio:
      </p>
      <ul>
        <li>Información personal como su nombre, dirección de correo electrónico y nombre de usuario.</li>
        <li>Datos de navegación y uso, como dirección IP, tipo de dispositivo, navegador utilizado y páginas visitadas.</li>
      </ul>

      <h2>2. Uso de la Información</h2>
      <p>
        Utilizamos la información recopilada para:
      </p>
      <ul>
        <li>Mejorar la experiencia del usuario en nuestro sitio.</li>
        <li>Enviar comunicaciones relevantes y actualizaciones de servicio.</li>
        <li>Responder a solicitudes de soporte o asistencia técnica.</li>
      </ul>

      <h2>3. Uso de Cookies</h2>
      <p>
        Utilizamos cookies para mejorar la funcionalidad del sitio y personalizar su experiencia. Las cookies son pequeños archivos que se almacenan en su dispositivo. Puede ajustar la configuración de su navegador para rechazar las cookies si lo prefiere.
      </p>

      <h2>4. Compartición de la Información</h2>
      <p>
        No compartimos su información personal con terceros, excepto cuando sea necesario para cumplir con obligaciones legales o para proteger los derechos, la propiedad o la seguridad de Rescate 360 o de otros usuarios.
      </p>

      <h2>5. Seguridad</h2>
      <p>
        Nos comprometemos a proteger su información personal mediante el uso de medidas de seguridad técnicas y administrativas apropiadas. Sin embargo, ningún método de transmisión o almacenamiento de datos es 100% seguro.
      </p>

      <h2>6. Sus Derechos</h2>
      <p>
        Usted tiene derecho a acceder, rectificar o eliminar su información personal. Para ejercer estos derechos, por favor contáctenos en la dirección proporcionada al final de esta política.
      </p>

      <h2>7. Cambios a esta Política de Privacidad</h2>
      <p>
        Podemos actualizar esta política de privacidad en cualquier momento. Le notificaremos cualquier cambio mediante un aviso en nuestro sitio web. Le recomendamos que revise esta política periódicamente.
      </p>

      <h2>8. Atribuciones y derechos de uso</h2>
      <p>
        Iconos obtenidos de: <a href="https://www.freepik.com/author/smashicons/icons/basic-miscellany-lineal-color_311">Icon by Smashicons</a>
      </p>

      <h2>9. Contacto</h2>
      <p>
        Si tiene preguntas o dudas sobre nuestra política de privacidad, puede contactarnos en: soporte@rescate360.com.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
