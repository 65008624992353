import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './UserProfile.css';

function UserProfile() {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Obtener el nombre de usuario del sessionStorage
    const username = sessionStorage.getItem("username");
    if (!username) {
      // Redirigir al login si no hay un usuario en sessionStorage
      navigate('/login');
      return;
    }

    // Función para obtener datos del usuario desde la API
    const fetchUserData = async () => {
      try {
        const response = await fetch(`https://rescate-backend.vercel.app/user/${username}`);
        if (!response.ok) {
          throw new Error("No se pudieron cargar los datos del usuario.");
        }
        const data = await response.json();
        setUserData(data);
      } catch (err) {
        setError(err.message);
      }
    };

    // Llamar a la función para obtener datos
    fetchUserData();
  }, [navigate]);

  const handleDeleteAccount = async () => {
    if (window.confirm("¿Estás seguro de que deseas eliminar tu cuenta? Esta acción no se puede deshacer.")) {
      try {
        // Llamada al servicio de eliminación de usuario (aquí se asume que existe en el backend)
        const response = await fetch(`https://rescate-backend.vercel.app/user/delete/${userData.username}`, {
          method: 'DELETE',
        });
        if (response.ok) {
          // Eliminar los datos del usuario de sessionStorage y redirigir al login
          sessionStorage.clear();
          navigate('/login');
        } else {
          setError("Error al eliminar la cuenta. Intenta de nuevo.");
        }
      } catch (error) {
        setError("Error en el servidor. Intenta más tarde.");
      }
    }
  };

  return (
    <div className="user-profile-container">
      {error ? (
        <p className="error-message">{error}</p>
      ) : userData ? (
        <div className="user-profile-content">
          <h2>Perfil de usuario</h2>
          <p><strong>Nombre:</strong> {userData.firstname}</p>
          <p><strong>Apellidos:</strong> {userData.lastname}</p>
          <p><strong>Nombre de usuario:</strong> {userData.username}</p>
          <p><strong>Email:</strong> {userData.email}</p>
          <button className="delete-account-button" onClick={handleDeleteAccount}>
            Eliminar Cuenta
          </button>
        </div>
      ) : (
        <p>Cargando...</p>
      )}
    </div>
  );
}

export default UserProfile;
