import React, { useState } from "react";
import CircularProgress from '@mui/joy/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { handleButtonClick as handleLogin } from "./LoginFormJs";
import './Login.css';

function LoginForm() {
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorUser, setErrorUser] = useState(false);
  const [isErrorPassword, setErrorPassword] = useState(false);
  const navigate2 = useNavigate();

  const handleButtonAccess = async (event) => {
    setIsLoading(true);
    const result = await handleLogin(event);
    setIsLoading(false);
  
    if (result.error) {
      setErrorMessage(result.error);
      if (result.code === "001") {
        setErrorUser(true);
        setErrorPassword(false);
      } else if (result.code === "002") {
        setErrorPassword(true);
        setErrorUser(false);
      }
    } else {
      setErrorMessage(""); 
      // Guardamos un indicador en sessionStorage para indicar que el usuario está logado
      //setUsername(form.elements.username.value); 
      sessionStorage.setItem("isAuthenticated", "true");
      navigate2('/');
    }
  };

  const navigate = useNavigate();
  const onRegister = () => {
    navigate('/register');
  };

  return (
    <div className="login-container">
      <div className="login-form-container">
        <form className="login-form">
          <h2>Acceso</h2>
          <div className="form-group">
            <label htmlFor="username">Usuario:</label>
            <input
              type="text"
              id="username"
              name="username"
              className={`${isErrorUser ? "input-error" : ""}`}
              required
              placeholder="Introduce tu usuario"
            />
          </div>
          {isErrorUser && (
            <span className="message-error">{errorMessage}</span>
          )}

          <div className="form-group">
            <label htmlFor="password">Contraseña:</label>
            <input
              type="password"
              id="password"
              name="password"
              className={`${isErrorPassword ? "input-error" : ""}`}
              required
              placeholder="Introduce tu contraseña"
            />
            <div className="forgot-password">
              <a href="/forgot-password">¿Olvidaste tu contraseña?</a>
            </div>
          </div>
          {isErrorPassword && (
            <span className="message-error">{errorMessage}</span>
          )}

          <div className="form-actions">
          {isLoading ? (
              <CircularProgress className="circular-progress" />
            ) : (
              <button
                type="button"
                onClick={handleButtonAccess}
                className="login-button"
              >
                Acceder
              </button>
            )}
            <button
              type="button"
              onClick={onRegister}
              className="register-button"
            >
              ¿No tienes cuenta? Regístrate
            </button>
           
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;
