// src/components/Sidebar.js
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaHome,
  FaPlus,
  FaSignInAlt,
  FaSignOutAlt,
  FaList,
  FaInfoCircle,
  FaPhone,
  FaUser
} from "react-icons/fa"; // Importa los íconos
import "./Sidebar.css";

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const buttonRef = useRef(null); // Referencia para el botón

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setIsOpen(false);
  };

  const isAuthenticated = sessionStorage.getItem("isAuthenticated");
  const username = sessionStorage.getItem("username");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current && 
        !menuRef.current.contains(event.target) && 
        buttonRef.current && 
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="sidebar">
<button
  ref={buttonRef}
  className={`hamburger-btn ${isOpen ? "active" : ""}`} // Añadimos la clase "active" cuando isOpen es true
  onClick={toggleMenu}
>
  ☰
</button>

      {isOpen && (
        <div className="menu open" ref={menuRef}>
          <div className="menu-item" onClick={() => handleNavigation("/")}>
            <FaHome className="menu-icon" /> Inicio
          </div>
          {isAuthenticated === "true" &&
            username !== null &&
            username !== "" && (
              <div
                className="menu-item"
                onClick={() => handleNavigation("/user-profile")}
              >
                <FaUser className="menu-icon" /> Mi perfil
              </div>
            )}
          <div
            className="menu-item"
            onClick={() => handleNavigation("/create-site")}
          >
            <FaPlus className="menu-icon" /> Publicar Sitio
          </div>
          {isAuthenticated === "true" &&
            username !== null &&
            username !== "" && (
              <div
                className="menu-item"
                onClick={() => handleNavigation("/my-sites")}
              >
                <FaList className="menu-icon" /> Mis Sitios
              </div>
            )}
          <div
            className="menu-item"
            onClick={() => handleNavigation("/about-us")}
          >
            <FaInfoCircle className="menu-icon" /> Sobre nosotras
          </div>

          <div
            className="menu-item"
            onClick={() => handleNavigation("/contact")}
          >
            <FaPhone className="menu-icon" /> Contacto
          </div>

          {(isAuthenticated === "false" ||
            username === null ||
            username === "") && (
            <div
              className="menu-item"
              onClick={() => handleNavigation("/login")}
            >
              <FaSignInAlt className="menu-icon" /> Iniciar sesión
            </div>
          )}

          {isAuthenticated === "true" &&
            username !== null &&
            username !== "" && (
              <div
                className="menu-item"
                onClick={() => handleNavigation("/logout")}
              >
                <FaSignOutAlt className="menu-icon" /> Cerrar sesión
              </div>
            )}
        </div>
      )}
    </div>
  );
}

export default Sidebar;
