// src/components/EmergencyAlert/EmergencyAlert.js
import React, { useState, useEffect } from "react";
import "./EmergencyAlert.css";

function EmergencyAlert() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Mostrar el aviso solo la primera vez que el usuario accede a la página
    const hasSeenAlert = localStorage.getItem("hasSeenAlert");
    if (!hasSeenAlert) {
      setIsVisible(true);
      localStorage.setItem("hasSeenAlert", "true");
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="emergency-alert-overlay">
      <div className="emergency-alert">
        <h2>⚠️ Aviso Importante ⚠️</h2>
        <p>
          Para cualquier emergencia o rescate en España el teléfono es el <strong>112</strong>.
          Esta web no está asociada a ningún organismo oficial y no atenderá emergencias. 
          Únicamente es un medio para conectar a personas que, tras una catástrofe, necesiten ayuda
          (voluntarios para limpieza, alimentación, etc.) con quienes deseen ayudar.
        </p>
        <button className="close-button" onClick={handleClose}>Aceptar</button>
      </div>
    </div>
  );
}

export default EmergencyAlert;
