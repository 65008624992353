// PasswordRecovery.js
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './PasswordRecovery.css'; // Importar estilos externos

const PasswordRecovery = () => {
  const { token } = useParams(); // Obtiene el token de la URL
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  // Validación de la contraseña
  const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;"'|\\<>,.?/~`-])[A-Za-z\d!@#$%^&*()_+{}\[\]:;"'|\\<>,.?/~`-]{8,}$/;

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Verificación de campos vacíos
    if (!newPassword.trim() || !confirmPassword.trim()) {
      setErrorMessage('Por favor completa ambos campos de contraseña.');
      return;
    }

    // Verificación de coincidencia de contraseñas
    if (newPassword !== confirmPassword) {
      setErrorMessage('Las contraseñas no coinciden.');
      return;
    }

    // Validación de la contraseña
    if (!passwordRegex.test(newPassword)) {
      setErrorMessage(
        "La contraseña debe tener al menos 8 caracteres, una mayúscula, un número y un símbolo."
      );
      return;
    }

    try {
      const response = await fetch(`https://rescate-backend.vercel.app/user/recovery/${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ newPassword }),
      });

      if (response.ok) {
        const data = await response.json();
        setSuccessMessage(data.message);
        alert('Contraseña cambiada correctamente');
        setErrorMessage('');
        setTimeout(() => navigate('/login'), 3000); // Redirige a la página de inicio de sesión tras 3 segundos
      } else {
        const errorData = await response.json();
        alert('Error al actualizar la contraseña. Intentelo más tarde o contacte con el administrador');
        setErrorMessage(errorData.error || 'Error al actualizar la contraseña');
      }
    } catch (error) {
      setErrorMessage('Error al conectar con el servidor');
    }
  };

  return (
    <div className="password-recovery">
      <h2>Recuperación de Contraseña</h2>
      {successMessage ? (
        <p className="success-message">{successMessage}</p>
      ) : (
        <form onSubmit={handleSubmit} className="password-form">
          <label htmlFor="newPassword">Nueva Contraseña:</label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <label htmlFor="confirmPassword">Confirmar Nueva Contraseña:</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <button type="submit">Actualizar Contraseña</button>
        </form>
      )}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default PasswordRecovery;
