// src/App.js
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Map from "./components/Map";
import Sidebar from "./components/Sidebar";
import CreateSite from "./components/Locations/CreateSite";
import SiteDetail from "./components/SiteByID/SiteDetail";
import LoginForm from "./components/Login/LoginForm";
import RegisterForm from "./components/Login/RegisterForm";
import ForgotPasswordForm from "./components/Login/ForgotPasswordForm";
import MySites from "./components/MySites/MySites";
import Logout from "./components/Logout/Logout";
import PrivateRoute from "./components/User/PrivateRoute";
import CancelationRequestPage from './components/SiteByID/CancelationRequestPage';
import PasswordRecovery from './components/PasswordRecovery/PasswordRecovery';
import UserProfile from './components/UserProfile/UserProfile';
import AboutUs from './components/AboutUs/AboutUs';
import 'font-awesome/css/font-awesome.min.css';
import logo from "./assets/logo.png";
import ContactUs from "./components/ContactUs/ContactUs";
import CookieConsent from "./components/CookieConsent/CookieConsent";
import PrivacyPolicy from "./components/CookieConsent/PrivacyPolicy";
import UpdateSite from "./components/UpdateSite/UpdateSite";
import EmergencyAlert from "./components/EmergencyAlert/EmergencyAlert";
import { Analytics } from "@vercel/analytics/react"

import "./App.css";

function AppContainer() {
  const location = useLocation();

  const hideLegend =
    location.pathname === "/login" ||
    location.pathname === "/register" ||
    location.pathname === "/logout" ||
    location.pathname === "/forgot-password" ||
    location.pathname.startsWith("/site/") ||
    location.pathname === "/my-sites" ||
    location.pathname === "/cancelation-request" ||
    location.pathname === "/about-us" ||
    location.pathname === "/create-site"||
    location.pathname === "/user-profile"||
    location.pathname === "/privacy-policy"||
    location.pathname === "/update-site"||
    location.pathname === "/contact"||
    /^\/recovery\/[^/]+$/.test(location.pathname);    

  if (sessionStorage.getItem("username") !== "") {
    sessionStorage.setItem("isAuthenticated", true);
  } else {
    sessionStorage.setItem("isAuthenticated", false);
  }

  return (
    <div className="app-container">
      <Analytics/>
      {/* Contenedor de la parte superior: Título, Leyenda y Botones */}
      <EmergencyAlert />
      <div className="top-container">
        {/* Logo */}
        <div className="title">
        <img src={logo} alt="Rescate 360 Logo" className="logo" />
          <span className="title-text">Rescate 360</span>
        </div>

        {/* Condicional para ocultar la leyenda en Login y Register */}
        {!hideLegend && (
          <div className="sidebar-left">
            <div className="legend">
              <h3>Leyenda</h3>
              <div className="legend-item">
                <img
                  src="/icons/affectedBuild.png"
                  alt="Edificio Afectado"
                  style={{ width: 30, height: 30 }}
                />
                <span>Edificio Afectado</span>
              </div>
              <div className="legend-item">
                <img
                  src="/icons/pickUpPoint.png"
                  alt="Punto de recogida de material"
                  style={{ width: 30, height: 30 }}
                />
                <span>Punto de recogida de material</span>
              </div>
            </div>
          </div>
        )}

        {/* Barra de botones a la derecha (Sidebar) */}
        <Sidebar />
      </div>

      {/* Contenedor principal del mapa */}
      <div className="map-container">
        {/* Rutas */}
        <Routes>
          <Route path="/" element={<Map />} />
          <Route
            path="/create-site"
            element={
              <PrivateRoute>
                <CreateSite />
              </PrivateRoute>
            }
          />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/about-us" element={<AboutUs />} />
          {/* <Route path="/site" element={<SiteDetail />} /> */}
          <Route path="/cancelation-request" element={<CancelationRequestPage />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/register" element={<RegisterForm />} />
          <Route path="/forgot-password" element={<ForgotPasswordForm />} />
          <Route path="/recovery/:token" element={<PasswordRecovery />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/user-profile" element={<UserProfile />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/update-site" element={<UpdateSite />} />
          <Route path="/site/:id" element={<SiteDetail />} />

          UserProfile
          
          <Route
            path="/my-sites"
            element={
              <PrivateRoute>
                <MySites />
              </PrivateRoute>
            }
          />
        </Routes>
        <CookieConsent />
        
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContainer />
    </Router>
  );
}

export default App;