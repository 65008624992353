// srv/components/Logout/Logout.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Necesitas react-router-dom para la redirección
import './Logout.css';

const Logout = () => {

  const navigate = useNavigate();

  const handleLogout = () => {
    // Limpiar la autenticación en localStorage
    sessionStorage.setItem("isAuthenticated","false");
    sessionStorage.setItem("username",""); // Si tienes el nombre de usuario, bórralo también
    // Redirigir al usuario a la página de inicio de sesión o a una página pública
    navigate("/"); // Cambia "/login" a la ruta de tu página de inicio de sesión
  };

  const handleCancelLogout = () => {
    navigate("/"); // Cambia "/login" a la ruta de tu página de inicio de sesión
  };

  return (
    <div className="logout-container">
        <div className="confirm-modal">
          <div className="confirm-modal-content">
            <p>¿Desea cerrar la sesión?</p>
            <div className="confirm-modal-buttons">
              <button onClick={handleLogout} className="confirm-button">
                Aceptar
              </button>
              <button onClick={handleCancelLogout} className="cancel-button">
                Cancelar
              </button>
            </div>
          </div>
        </div>

    </div>
  );
};

export default Logout;