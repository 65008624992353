import React, { useEffect, useRef, useState } from "react";
import "ol/ol.css";
import { Map, View } from "ol";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { OSM } from "ol/source";
import { Vector as VectorSource } from "ol/source";
import { Point } from "ol/geom";
import { Feature } from "ol";
import { fromLonLat } from "ol/proj";
import { Style, Icon } from "ol/style";
import Overlay from "ol/Overlay";
import { useNavigate } from 'react-router-dom';
import municipiosData from "./locationModel/municipios.json"; // Importamos los datos de municipios
import provinciasData from "./locationModel/provincias.json"; // Importamos los datos de provincias
import './Map.css';

const MapComponent = () => {
  const mapRef = useRef();
  const overlayRef = useRef();
  const [map, setMap] = useState(null);
  const [selectedFeature, setSelectedFeature] = useState(null);

  useEffect(() => {
    const vectorSource = new VectorSource();

    const fetchData = async () => {
      try {
        const response = await fetch("https://rescate-backend.vercel.app/location");
        const data = await response.json();

        data.forEach((site) => {
          const feature = new Feature({
            geometry: new Point(fromLonLat([parseFloat(site.longitude), parseFloat(site.latitude)])),
            name: site.description,
            type: site.type,
            street: site.street,
            number: site.number,
            municipality: site.municipality,
            province: site.province,
            locationid: site.locationid // Añade locationid
          });
          let iconStyle;
          if(site.type === "affectedBuild"){

          
          iconStyle = new Style({
            image: new Icon({
              src: `/icons/${site.type}.png`,
              scale: 0.08,
            }),
          });
        }
          if(site.type === "pickUpPoint"){
            iconStyle = new Style({
              image: new Icon({
                src: `/icons/${site.type}.png`,
                scale: 0.1,
              }),
            });
          }
          feature.setStyle(iconStyle);
          vectorSource.addFeature(feature);
        });
      } catch (error) {
        console.error("Error al obtener los datos de la API:", error);
      }
    };

    fetchData();

    const vectorLayer = new VectorLayer({
      source: vectorSource,
    });

    const olMap = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        vectorLayer,
      ],
      view: new View({
        center: fromLonLat([-3.74922, 38.463667]),
        zoom: 6,
      }),
    });

    let overlay;
    if (overlayRef.current) {
      overlay = new Overlay({
        element: overlayRef.current,
        autoPan: true,
        positioning: "bottom-center",
        stopEvent: false,
      });
      olMap.addOverlay(overlay);
    }

    olMap.on("singleclick", (event) => {
      const feature = olMap.forEachFeatureAtPixel(event.pixel, (feature) => feature);
      if (feature && overlay) {
        setSelectedFeature({
          id: feature.get("locationid"), // Guardar el locationid en selectedFeature
          name: feature.get("name"),
          type: feature.get("type"),
          street: feature.get("street"),
          number: feature.get("number"),
          municipality: feature.get("municipality"),
          province: feature.get("province"), 
        });
        overlay.setPosition(event.coordinate);
      } else {
        setSelectedFeature(null);
        overlay.setPosition(undefined); // Oculta el overlay
      }
    });

    setMap(olMap);

    return () => olMap.setTarget(null);
  }, []);

  const navigate = useNavigate();

  const onPointById = () => {
    sessionStorage.setItem("locationId", "")
    if (selectedFeature?.id) {
      sessionStorage.setItem("locationId", selectedFeature?.id)
      navigate(`/site/${selectedFeature?.id}`);
    }
  };

      // Función para obtener el nombre del municipio a partir del ID
      const getMunicipioNombre = (municipioId) => {
        const municipio = municipiosData.find(
          (m) => m.municipio_id === municipioId
        );
        return municipio ? municipio.nombre : "Municipio desconocido";
      };
    
      // Función para obtener el nombre de la provincia a partir del ID
      const getProvinciaNombre = (provinciaId) => {
        const provincia = provinciasData.find(
          (p) => p.provincia_id === provinciaId
        );
        return provincia ? provincia.nombre : "Provincia desconocida";
      };
  return (
    
    <div>
      
      <div ref={mapRef} style={{ width: "100%", height: "100vh" }}></div>

      {/* Popup */}
      <div ref={overlayRef} className={`ol-popup ${selectedFeature ? "visible" : "hidden"}`}>
        {selectedFeature && (
          <div className="popUpMap">
            <h3>Dirección: {selectedFeature.street}, {selectedFeature.number}</h3>
            <p>Municipio: {getMunicipioNombre(selectedFeature.municipality)}</p>
            <p>Provincia: {getProvinciaNombre(selectedFeature.province)}</p>
            <button type="button" onClick={onPointById} className="point-button">
              Más información de este sitio
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MapComponent;
