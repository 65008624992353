import React, { Component } from "react";
import "ol/ol.css";
import { Map, View } from "ol";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { OSM } from "ol/source";
import { Vector as VectorSource } from "ol/source";
import { Point } from "ol/geom";
import { Feature } from "ol";
import { fromLonLat, toLonLat } from "ol/proj";
import { Style, Icon } from "ol/style";
import Overlay from "ol/Overlay";
import "./CreateSite.css";
import provincias from "./locationModel/provincias.json";
import municipios from "./locationModel/municipios.json";
import Select from "react-select";
import recursos from "./locationModel/recursos.json";
import { handleButtonClick } from "./CreateSiteJS";

class CreateSite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        type: "init",
        resources: [],
        contact: "",
        phone: "",
        description: "",
        descriptionPoint: "",
        latitude: "",
        longitude: "",
        street: "",
        number: "",
        postalCode: "",
        province: "",
        municipality: "",
        additionalInfo: "",
      },
      map: null,
      overlay: null,
      municipiosList: [],
    };
    this.mapRef = React.createRef();
    this.overlayRef = React.createRef();
    this.vectorSource = new VectorSource();
  }

  componentDidMount() {
    const vectorLayer = new VectorLayer({
      source: this.vectorSource,
    });
    const map = new Map({
      target: this.mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        vectorLayer,
      ],
      view: new View({
        center: fromLonLat([-3.74922, 40.463667]),
        zoom: 6,
      }),
    });

    const overlay = new Overlay({
      element: this.overlayRef.current,
      positioning: "bottom-center",
      stopEvent: false,
      autoPan: false,
    });
    map.addOverlay(overlay);
    this.setState({ map, overlay });

    map.on("singleclick", (event) => {
      const coordinate = toLonLat(event.coordinate);
      this.setState({
        formData: {
          ...this.state.formData,
          latitude: coordinate[1],
          longitude: coordinate[0],
        },
      });
      const mapCoordinate = fromLonLat([coordinate[0], coordinate[1]]);
      overlay.setPosition(mapCoordinate);
      this.vectorSource.clear();

      const point = new Point(mapCoordinate);
      const iconStyle = new Style({
        image: new Icon({
          src: "/icons/point.png",
          scale: 0.05,
          anchor: [0.5, 0.8],
          anchorXUnits: "fraction",
          anchorYUnits: "fraction",
          imgSize: [16, 16],
          crossOrigin: "anonymous",
        }),
      });

      const feature = new Feature({
        geometry: point,
      });
      feature.setStyle(iconStyle);
      this.vectorSource.addFeature(feature);
    });
  }

  componentWillUnmount() {
    const { map } = this.state;
    if (map) {
      map.setTarget(null);
    }
  }

  handleChange = (selectedOption, actionMeta) => {
    // Si actionMeta está presente (es un cambio de react-select)
    if (actionMeta) {
      const { name } = actionMeta; // Obtener el nombre del campo (como 'province' o 'municipality')
      let value = selectedOption ? selectedOption.value : ""; // Asigna el valor del select o cadena vacía si no hay selección
      if (name == "resources") {
        value = selectedOption
          ? selectedOption.map((option) => option.value)
          : [];
      }
      console.log(name, value); // Depuración para ver los valores seleccionados

      this.setState(
        {
          formData: {
            ...this.state.formData,
            [name]: value, // Actualiza el valor en el estado según el campo
          },
        },
        () => {
          if (name === "province") {
            // Al cambiar la provincia, restablecer el municipio a '00001'
            const selectedMunicipios = municipios.filter(
              (mun) => mun.provincia_id === value
            );

            // Establecer el municipio por defecto a '00001' (el primer municipio de la provincia)
            const defaultMunicipality =
              selectedMunicipios.length > 0
                ? selectedMunicipios[0].municipio_id
                : "";

            this.setState({
              municipiosList: selectedMunicipios,
              formData: {
                ...this.state.formData,
                municipality: defaultMunicipality, // Cambia el municipio al primero de la lista
              },
            });
          }
        }
      );
    } else {
      // Si no hay actionMeta, es un campo de texto tradicional (como el código postal)
      const { name, value } = selectedOption.target || {}; // Si es campo de texto, tomar name y value

      this.setState({
        formData: {
          ...this.state.formData,
          [name]: value, // Actualizar el campo correspondiente
        },
      });
    }
  };

  validateMapPoint = () => {
    const { latitude, longitude } = this.state.formData;
    return latitude && longitude; // Devuelve true si ambos valores están definidos
  };

  handleSubmit = async (e) => {
    e.preventDefault(); // Prevenir el envío del formulario predeterminado

    // Verificar si hay un punto marcado en el mapa
    if (!this.validateMapPoint()) {
      alert("Por favor, marca una ubicación en el mapa antes de publicar.");
      return; // Detener el envío si no hay un punto
    }

    // Cambiar el estado de loading para mostrar que estamos procesando
    this.setState({ isLoading: true });

    const { formData } = this.state;

    try {
      const response = await handleButtonClick(formData);
      this.setState({ isLoading: false });

      if (response.success) {
        console.log("Ubicación publicada con éxito");
        window.location.href = "/";
        alert(`Ubicación creada con éxito`);
        // Puedes añadir más lógica aquí, como resetear el formulario o mostrar un mensaje
      } else {
        alert(`Error al publicar la ubicación. Compruebe los datos`);
        console.log("Error al publicar la ubicación:", response.error);
      }
    } catch (error) {
      console.log("Error durante el envío:", error);
      this.setState({ isLoading: false });
    }
  };

  handleGeocode = async () => {
    let { street, number, municipality, province, postalCode } =
      this.state.formData;

    if (!street || !number || !municipality || !province || !postalCode) {
      alert("Por favor, completa todos los campos de dirección.");
      return;
    }

    // Buscar el nombre del municipio a partir del ID del municipio
    const selectedMunicipality = municipios.find(
      (mun) => mun.municipio_id === municipality
    );

    // Si se encuentra el municipio, actualizar el estado con el nombre
    if (selectedMunicipality) {
      municipality = selectedMunicipality.nombre; // Actualizamos con el nombre del municipio
    }

    // Buscar el nombre de la provincia a partir del ID de la provincia
    const selectedProvince = provincias.find(
      (mun) => mun.provincia_id === province
    );

    // Si se encuentra la provincia, actualizar el estado con el nombre
    if (selectedProvince) {
      province = selectedProvince.nombre; // Actualizamos con el nombre de la provincia
    }

    const address = `${number} ${street}`;
    const city = municipality;
    const state = province;
    const postalCodeValue = postalCode;
    const country = "ES";

    const apiUrl = `https://geocode.maps.co/search?street=${encodeURIComponent(
      address
    )}&city=${encodeURIComponent(city)}&state=${encodeURIComponent(
      state
    )}&postalcode=${encodeURIComponent(
      postalCodeValue
    )}&country=${encodeURIComponent(country)}&api_key=${
      process.env.REACT_APP_GEOCODE_API_KEY
    }`;

    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("No se pudo obtener la ubicación");
      }

      const data = await response.json();

      if (data && data.length > 0) {
        const latitude = parseFloat(data[0].lat);
        const longitude = parseFloat(data[0].lon);

        this.setState({
          formData: {
            ...this.state.formData,
            latitude,
            longitude,
          },
        });

        this.updateMapPoint(longitude, latitude);
      } else {
        alert(
          "No se pudo encontrar la ubicación. Por favor, verifica la dirección."
        );
      }
    } catch (error) {
      console.error("Error al realizar la geocodificación:", error);
      alert("Error al buscar la dirección. Por favor intente de nuevo.");
    }
  };

  updateMapPoint = (longitude, latitude) => {
    const { overlay } = this.state;
    const mapCoordinate = fromLonLat([longitude, latitude]);
    overlay.setPosition(mapCoordinate);
    this.vectorSource.clear();

    const point = new Point(mapCoordinate);
    const iconStyle = new Style({
      image: new Icon({
        src: "/icons/point.png",
        scale: 0.05,
        anchor: [0.5, 0.8],
        anchorXUnits: "fraction",
        anchorYUnits: "fraction",
        imgSize: [16, 16],
        crossOrigin: "anonymous",
      }),
    });

    const feature = new Feature({
      geometry: point,
    });
    feature.setStyle(iconStyle);
    this.vectorSource.addFeature(feature);
  };

  render() {
    const isAuthenticated = sessionStorage.getItem("isAuthenticated");
    const username = sessionStorage.getItem("username");
    const { formData, municipiosList } = this.state;
    const resourcesOptions = recursos; // Ya tenemos los recursos disponibles
    // Preparar opciones para el select de provincias
    const provinceOptions = provincias.map((provincia) => ({
      value: provincia.provincia_id,
      label: provincia.nombre,
    }));

    // Preparar opciones para el select de municipios
    const municipalityOptions = municipiosList.map((municipio) => ({
      value: municipio.municipio_id,
      label: municipio.nombre,
    }));

    return (
      <div className="create-site-container">
        <div className="create-site-map" ref={this.mapRef}></div>

        <div className="create-site-form-container">
          <h2>Publicar una ubicación</h2>
          <h5>
              Aviso: Solo añadir ubicaciones que necesiten asistencia o puntos de recogida. Cualquier persona que quiera ayudar, deberá contactar directamente a la ubicación.
            </h5>
          {isAuthenticated === "true" &&
            username !== null &&
            username !== "" && (
              <form onSubmit={this.handleSubmit} className="create-site-form">
                <div className="form-group">
                  <label htmlFor="type">Tipo de sitio</label>
                  <select
                    name="type"
                    id="type"
                    value={formData.type}
                    onChange={this.handleChange}
                    required
                  >
                    <option value="init"></option>
                    <option value="affectedBuild">Edificio Afectado</option>
                    <option value="pickUpPoint">
                      Punto de Recogida de Material
                    </option>
                  </select>
                </div>
                {formData.type === "affectedBuild" && (
                  <div className="form-group">
                    <label htmlFor="resources">¿Qué necesitas?</label>
                    <Select
                      name="resources"
                      value={formData.resources.map((resourceId) =>
                        resourcesOptions.find(
                          (option) => option.value === resourceId
                        )
                      )}
                      onChange={this.handleChange}
                      options={resourcesOptions}
                      isMulti={true}
                      isSearchable={true}
                      placeholder="Selecciona los recursos necesarios"
                      required
                    />
                  </div>
                )}

                {(formData.type === "affectedBuild" ||
                  formData.type === "pickUpPoint") && (
                  <div className="form-group">
                    <label htmlFor="name">Persona de contacto en la zona</label>
                    <input
                      type="text"
                      name="contact"
                      id="contact"
                      placeholder="Nombre de una persona de contacto que esté en el edificio afectado"
                      value={formData.contact}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                )}

                {(formData.type === "affectedBuild" ||
                  formData.type === "pickUpPoint") && (
                  <div className="form-group">
                    <label>Teléfono de contacto</label>
                    <input
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={this.handleChange}
                      placeholder="Número de teléfono"
                      required
                    />
                  </div>
                )}

                {formData.type === "affectedBuild" && (
                  <div className="form-group">
                    <label htmlFor="description">
                      Información sobre el problema para que los voluntarios te
                      puedan ayudar
                    </label>
                    <textarea
                      name="description"
                      id="description"
                      placeholder="Sería importante decir cuantas personas vivis en la casa, animales, que tipo de ayuda necesitas, etc. Cuentanos sobre tu situación"
                      value={formData.description}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                )}
                {(formData.type === "affectedBuild" ||
                  formData.type === "pickUpPoint") && (
                  <div className="address-container">
                    {formData.type === "pickUpPoint" && (
                      <div className="form-group">
                        <label htmlFor="descriptionPoint">
                          Información sobre el punto de recogida de material
                        </label>
                        <textarea
                          name="descriptionPoint"
                          id="descriptionPoint"
                          placeholder="Puedes dar información sobre que puede llevar la gente o cualquier cosa que necesites"
                          value={formData.descriptionPoint}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    )}

                    <h3>Dirección</h3>
                    <h5>
              Aviso: Es necesario que haya un punto en el mapa antes de publicar. 
              Puedes hacer click en el mapa o buscar por dirección.
            </h5>
                    {(formData.type === "affectedBuild" ||
                      formData.type === "pickUpPoint") && (
                      <div className="form-group">
                        <label htmlFor="street">Calle</label>
                        <input
                          type="text"
                          name="street"
                          id="street"
                          placeholder="Calle"
                          value={formData.street}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    )}

                    <div className="form-row">
                      {(formData.type === "affectedBuild" ||
                        formData.type === "pickUpPoint") && (
                        <div className="form-group">
                          <label htmlFor="number">Número</label>
                          <input
                            type="text"
                            name="number"
                            id="number"
                            placeholder="Número"
                            value={formData.number}
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                      )}

                      {(formData.type === "affectedBuild" ||
                        formData.type === "pickUpPoint") && (
                        <div className="form-group">
                          <label htmlFor="postalCode">Código Postal</label>
                          <input
                            type="text"
                            name="postalCode"
                            id="postalCode"
                            placeholder="Código Postal"
                            value={formData.postalCode}
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                      )}
                    </div>

                    {(formData.type === "affectedBuild" ||
                      formData.type === "pickUpPoint") && (
                      <div className="form-group">
                        <label htmlFor="province">Provincia</label>
                        <Select
                          name="province"
                          value={provinceOptions.find(
                            (option) => option.value === formData.province
                          )}
                          onChange={this.handleChange}
                          options={provinceOptions}
                          isSearchable={true}
                          isClearable={false}
                          placeholder="Selecciona una provincia"
                          required
                        />
                      </div>
                    )}

                    {(formData.type === "affectedBuild" ||
                      formData.type === "pickUpPoint") && (
                      <div className="form-group">
                        <label htmlFor="municipality">Municipio</label>
                        <Select
                          name="municipality"
                          value={municipalityOptions.find(
                            (option) => option.value === formData.municipality
                          )}
                          onChange={this.handleChange}
                          options={municipalityOptions}
                          isSearchable={true}
                          isClearable={false}
                          placeholder="Selecciona un municipio"
                          required
                        />
                      </div>
                    )}

                    {(formData.type === "affectedBuild" ||
                      formData.type === "pickUpPoint") && (
                      <div className="form-group">
                        <label htmlFor="additionalInfo">
                          Información adicional de la dirección
                        </label>
                        <textarea
                          name="additionalInfo"
                          id="additionalInfo"
                          placeholder="Información adicional"
                          value={formData.additionalInfo}
                          onChange={this.handleChange}
                        />
                      </div>
                    )}

                    <button
                      type="button"
                      onClick={this.handleGeocode}
                      className="geocode-btn"
                    >
                      Buscar dirección
                    </button>
                  </div>
                )}
                {(formData.type === "affectedBuild" ||
                  formData.type === "pickUpPoint") && (
                  <button
                    type="submit"
                    className="submit-btn"
                    disabled={
                      !(
                        formData.type === "affectedBuild" ||
                        formData.type === "pickUpPoint"
                      )
                    }
                  >
                    Publicar
                  </button>
                )}
              </form>
            )}

          {(isAuthenticated === "false" ||
            username === null ||
            username === "") && (
              <h4>
              Para publicar necesita estar autenticado. Si tiene usuario puede{" "}
              <a href="/login" style={{ color: "#007bff", textDecoration: "underline" }}>
                iniciar sesión
              </a>{" "}
              y sino puede{" "}
              <a href="/register" style={{ color: "#007bff", textDecoration: "underline" }}>
                registrarse
              </a>.
            </h4>
            
          )}
        </div>

        {/* <div ref={this.overlayRef} className="ol-popup"></div> */}
      </div>
    );
  }
}

export default CreateSite;
