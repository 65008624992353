import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/joy/CircularProgress";
import "./Register.css";

function RegisterForm() {
  // Estados para el formulario de registro
  const [firstName, setFirstName] = useState(""); // Nombre
  const [lastName, setLastName] = useState(""); // Apellidos
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState(""); // Estado para el email
  const [isLoading, setIsLoading] = useState(false); // Indicador de carga
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorFirstName, setIsErrorFirstName] = useState(false);
  const [isErrorLastName, setIsErrorLastName] = useState(false);
  const [isErrorUsername, setIsErrorUsername] = useState(false); // Nuevo estado para nombre de usuario
  const [isErrorPassword, setIsErrorPassword] = useState(false);
  const [isErrorConfirmPassword, setIsErrorConfirmPassword] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false); // Nuevo estado para email

  const navigate = useNavigate();

  // Función para manejar el registro
  const handleRegister = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    // Validación de formulario
    if (
      !firstName ||
      !lastName ||
      !username ||
      !password ||
      !confirmPassword ||
      !email
    ) {
      setIsLoading(false);
      setErrorMessage("Todos los campos son obligatorios.");
      return;
    }

    if (password !== confirmPassword) {
      setIsLoading(false);
      setErrorMessage("Las contraseñas no coinciden.");
      return;
    }

    // Validación de la contraseña
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;"'|\\<>,.?/~`-])[A-Za-z\d!@#$%^&*()_+{}\[\]:;"'|\\<>,.?/~`-]{8,}$/;
    if (!passwordRegex.test(password)) {
      setIsLoading(false);
      setErrorMessage(
        "La contraseña debe tener al menos 8 caracteres, una mayúscula, un número y un símbolo."
      );
      return;
    }

    // Validación del nombre de usuario (al menos 3 caracteres, sin espacios ni caracteres especiales)
    const usernameRegex = /^[a-zA-Z0-9_]{6,}$/;
    if (!usernameRegex.test(username)) {
      setIsLoading(false);
      setErrorMessage("El nombre de usuario debe tener al menos 6 caracteres y no puede contener espacios ni caracteres especiales.");
      return;
    }

    // Validación básica de email
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(email)) {
      setIsLoading(false);
      setErrorMessage("Por favor, introduce un email válido.");
      return;
    }

    // Aquí puedes agregar la lógica de registro, como hacer una solicitud API para crear el usuario
    try {
      // Simulación de llamada API para el registro
      // const result = await apiRegister(username, password, email);

      const response = await fetch(
        "https://rescate-backend.vercel.app/user/new",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: username.toLowerCase().trim(),
            password: password,
            firstname: firstName,
            lastname: lastName,
            email: email,
            role: "solicitor",
          }),
        }
      );
      const data = await response.json();
      const result = { success: true }; // Simulación de respuesta exitosa
      if (result.success) {
        navigate("/login"); // Redirigir al login si el registro es exitoso
      } else {
        setErrorMessage("Error al registrar el usuario. Intenta nuevamente.");
      }
    } catch (error) {
      setErrorMessage("Error en el servidor. Intenta más tarde.");
    }

    setIsLoading(false);
  };

  return (
    <div className="register-container">
      <div className="register-form-container">
        <form className="register-form" onSubmit={handleRegister}>
          <h2>Crear cuenta</h2>

          {/* Primer fila: Nombre y Apellidos */}
          <div className="form-group-group">
            <div className="form-group">
              <label htmlFor="first-name">Nombre:</label>
              <input
                type="text"
                id="first-name"
                name="first-name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className={`${isErrorFirstName ? "error-input" : ""}`}
                required
                placeholder="Introduce tu nombre"
              />
            </div>

            <div className="form-group">
              <label htmlFor="last-name">Apellidos:</label>
              <input
                type="text"
                id="last-name"
                name="last-name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className={`${isErrorLastName ? "error-input" : ""}`}
                required
                placeholder="Introduce tus apellidos"
              />
            </div>
          </div>

          {/* Segunda fila: Nombre de usuario y Correo */}
          <div className="form-group-group">
            <div className="form-group">
              <label htmlFor="username">Nombre de usuario:</label>
              <input
                type="text"
                id="username"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className={`${isErrorUsername ? "error-input" : ""}`}
                required
                placeholder="Introduce tu nombre de usuario"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Correo electrónico:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={`${isErrorEmail ? "error-input" : ""}`}
                required
                placeholder="Introduce tu correo electrónico"
              />
            </div>
          </div>

          {/* Tercera fila: Contraseña y Confirmar contraseña */}
          <div className="form-group-group">
            <div className="form-group">
              <label htmlFor="password">Contraseña:</label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={`${isErrorPassword ? "error-input" : ""}`}
                required
                placeholder="Introduce tu contraseña"
              />
            </div>

            <div className="form-group">
              <label htmlFor="confirm-password">Confirmar contraseña:</label>
              <input
                type="password"
                id="confirm-password"
                name="confirm-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className={`${isErrorConfirmPassword ? "error-input" : ""}`}
                required
                placeholder="Confirma tu contraseña"
              />
            </div>
          </div>

          {/* Mensajes de error */}
          {errorMessage && (
            <label className="error-message">{errorMessage}</label>
          )}

          {/* Botón de registro */}
          <div className="register-actions">
            {isLoading ? (
              <CircularProgress className="circular-progress" />
            ) : (
              <button type="submit" className="register-button">
                Registrar
              </button>
            )}
          </div>
        </form>

        {/* Enlace para volver al login */}
        <div className="login-link">
          <p>
            ¿Ya tienes una cuenta? <a href="/login">Iniciar sesión</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default RegisterForm;
