export const handleButtonClick = async (event) => {
  event.preventDefault();

  const form = event.target.closest("form");
  const username = form.elements.username.value.toLowerCase().trim();
  const password = form.elements.password.value;

  try {
    const response = await fetch("https://rescate-backend.vercel.app/user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      //body: JSON.stringify({ username, password }),
      body: JSON.stringify({ username: username, password: password }),
    });
    const data = await response.json();
    if (!response.ok) {
      return {
        error: data.statusText,
        code: data.codeError,
      };
      //throw new Error(data.statusText || 'Ocurrió un error al iniciar sesión');
    }
    console.log("Data fetched successfully:", data);
    sessionStorage.setItem("username", username);
    form.reset(); // Resetear el formulario (opcional)
    return { success: true }; // Devuelve un objeto indicando éxito
  } catch (error) {
    console.log("Error fetching data:", error);
    //return { error: error.message }; // Devuelve el mensaje de error
  }
};
