export const handleButtonClick = async (formData) => {
  // Desestructuramos formData y asignamos los valores necesarios
  let {
    additionalInfo,
    description,
    descriptionPoint,
    latitude,
    longitude,
    municipality,
    contact,
    number,
    phone,
    postalCode,
    province,
    resources,
    street,
    type
  } = formData;

  const username = sessionStorage.getItem("username");

  // Convertimos resources en el formato requerido
  const resourcesObject = resources.reduce((acc, resource) => {
    acc[resource] = true;
    return acc;
  }, {});

  // Preparamos el cuerpo de la petición
  const bodyData = {
    username,
    additionalInfo,
    description,
    descriptionPoint,
    latitude,
    longitude,
    municipality,  // Asegúrate de enviar el nombre y no el ID
    contact,
    number,
    phone,
    postalCode,
    province,  // Asegúrate de enviar el nombre y no el ID
    street,
    type,
    resources: [resourcesObject] // Envolvemos en un array como el ejemplo exitoso
  };

  try {
    const response = await fetch("https://rescate-backend.vercel.app/location", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyData),
    });

    const data = await response.json();
    if (!response.ok) {
      return {
        error: data.statusText,
        code: data.codeError,
      };
    }
    console.log("Data fetched successfully:", data);
    return { success: true }; // Devuelve un objeto indicando éxito
  } catch (error) {
    console.log("Error fetching data:", error);
    return { error: error.message }; // Devuelve el mensaje de error
  }
};