import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './CancelationRequestPage.css';

const CancelationRequestPage = () => {
  //const { id } = useParams(); // Obtenemos el ID de la ubicación de la URL
  const [reason, setReason] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");
  const id = sessionStorage.getItem("locationId");

  if (isAuthenticated !== "true") {
    navigate('/login');
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!reason.trim()) {
      setError("El motivo no puede estar vacío.");
      return;
    }

    try {
      const response = await fetch(`https://rescate-backend.vercel.app/cancellation-request/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ motivo:reason }),
      });

      if (response.ok) {
        alert("Solicitud de cancelación enviada.");
        navigate('/'); // Redirigir a la página de sitios después de enviar
      } else {
        setError("Hubo un problema al enviar la solicitud.");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Error al enviar la solicitud.");
    }
  };

  return (
    <div className="cancelation-request-page">
      <h2>Solicitud de Cancelación</h2>
      <form onSubmit={handleSubmit}>
        <textarea
          placeholder="Escribe el motivo de la cancelación..."
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          rows="5"
        />
        <button className="btnSendCancel" type="submit">Enviar Solicitud</button>
      </form>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default CancelationRequestPage;
