import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./MySites.css";
import { FaEdit, FaTrashAlt, FaMapMarkerAlt, FaInfo } from "react-icons/fa";
import { GiCardPickup } from "react-icons/gi"; // Importa los íconos

const MySites = () => {
  const [locations, setLocations] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Estado para saber si estamos cargando
  const navigate = useNavigate();

  const isAuthenticated = sessionStorage.getItem("isAuthenticated");
  const username = sessionStorage.getItem("username");

  useEffect(() => {
    if (!isAuthenticated || isAuthenticated === "false") {
      // Si no está autenticado, redirige al login
      navigate("/login");
    } else {
      // Si está autenticado, hace la llamada para obtener las ubicaciones
      fetchLocations();
    }
  }, [isAuthenticated, navigate]);

  const fetchLocations = async () => {
    try {
      const response = await fetch(
        `https://rescate-backend.vercel.app/location/username/${username}`
      );
      if (!response.ok) {
        throw new Error("No se encontraron ubicaciones para el usuario");
      }
      const data = await response.json();
      setLocations(data); // Guardar las ubicaciones en el estado
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false); // Cambiar el estado de carga a falso después de la respuesta
    }
  };

  const handleDelete = async (locationId) => {
    const confirmDelete = window.confirm("¿Estás seguro de que deseas eliminar esta ubicación?");
    
    if (confirmDelete) {
      try {
        const response = await fetch(
          `https://rescate-backend.vercel.app/location/delete/${locationId}`,
          {
            method: "POST",
          }
        );
        if (!response.ok) {
          throw new Error("Error al eliminar la ubicación");
        }
        const data = await response.json();
        // Si la eliminación es exitosa, filtra la ubicación eliminada de la lista
        setLocations(
          locations.filter((location) => location.locationid !== locationId)
        );
        alert(`Ubicación eliminada con éxito`);
      } catch (error) {
        alert(error.message);
      }
    }
  };

  const handleEdit = (locationId) => {
    sessionStorage.setItem("locationId",locationId);
    navigate(`/update-site`); 
  };

  const handleView = (locationId) => {
    // Aquí podrías redirigir a una página de detalles o mostrar más información
    sessionStorage.setItem("locationId",locationId);
    navigate(`/site/${locationId}`);
    //alert(`Ver ubicación con ID: ${locationId}`);
  };

  return (
    <div className="my-sites-container">
      <h2>Mis Sitios</h2>

      {/* Mostrar mensaje de carga mientras se están cargando las ubicaciones */}
      {loading && <p>Cargando ubicaciones...</p>}

      {/* Si ocurrió un error */}
      {error && <p className="error-message">{error}</p>}

      {/* Si no hay error ni estamos cargando, mostramos las ubicaciones o mensaje si no hay */}
      {!loading && locations.length > 0 ? (
        <table className="locations-table">
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Contacto</th> {/* Nueva columna para el contacto */}
              <th>Dirección</th>
              <th>Información de la zona</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {locations.map((location) => (
              <tr key={location.locationid}>
                {location.type == "affectedBuild" && (
                  <td>
                    <FaMapMarkerAlt className="action-icon" />
                  </td>
                )}
                {location.type == "pickUpPoint" && (
                  <td>
                    <GiCardPickup className="action-icon" />
                  </td>
                )}
                <td>{location.contact}</td>{" "}
                {/* Mostrar el contacto en esta celda */}
                <td>
                  {location.street}, {location.number} - {location.municipality}
                </td>
                <td>{location.description}</td>
                <td>
                  <button
                    onClick={() => handleView(location.locationid)} // Nuevo manejador para ver
                    className="view-btn"
                  >
                    <FaInfo className="action-icon" />{" "}
                    {/* Icono de "Ver" */}
                  </button>
                  <button
                    onClick={() => handleEdit(location.locationid)}
                    className="edit-btn"
                  >
                    <FaEdit className="action-icon" />
                  </button>

                  <button
                    onClick={() => handleDelete(location.locationid)}
                    className="delete-btn"
                  >
                    <FaTrashAlt className="action-icon" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        // Si no hay ubicaciones después de cargar
        !loading && <p>No tienes ubicaciones asociadas.</p>
      )}
    </div>
  );
};

export default MySites;
