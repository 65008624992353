// src/components/CookieConsent.js
import React, { useState, useEffect } from "react";
import "./CookieConsent.css";

function CookieConsent() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Verifica si el usuario ya aceptó las cookies
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "true");
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="cookie-consent">
        <p>
          Usamos cookies para mejorar tu experiencia en nuestro sitio. Al continuar, aceptas nuestra{" "}
          <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
            Política de Privacidad
          </a>.
        </p>
        <button onClick={handleAccept} className="accept-button">
          Aceptar
        </button>
      </div>
    )
  );
}

export default CookieConsent;
