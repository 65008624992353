import React, { useState } from 'react';
import './ForgotPassword.css'; // Si quieres añadir estilos específicos para esta vista
import CircularProgress from '@mui/joy/CircularProgress';

function ForgotPasswordForm() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handlePasswordReset = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch('https://rescate-backend.vercel.app/user/recovery', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      setIsLoading(false);

      if (response.ok) {
        setMessage('Revisa tu correo electrónico para las instrucciones de restablecimiento.');
      } else {
        setMessage(data.statusText || 'Error al intentar restablecer la contraseña.');
      }
    } catch (error) {
      setIsLoading(false);
      setMessage('Ocurrió un error. Inténtalo de nuevo más tarde.');
    }
  };

  return (
    <div className="forgot-password-container">
      <h2>Restablecer Contraseña</h2>
      <form onSubmit={handlePasswordReset}>
        <div className="form-group">
          <label htmlFor="email">Correo Electrónico:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Introduce tu correo electrónico"
          />
        </div>
        {message && <p>{message}</p>}
        <div>
          {isLoading ? (
            <CircularProgress className="circular-progress" />
          ) : (
            <button type="submit" className="reset-button">
              Restablecer Contraseña
            </button>
          )}
        </div>
      </form>
    </div>
  );
}

export default ForgotPasswordForm;